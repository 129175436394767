<template>
  <div>
    <section class="py-3 px-5 page-banner" v-if="userDetails">
      <router-link to="/users" class="go-back-link"><BIconArrowLeftShort />Back to users</router-link>
      <h3 class="page-header py-3">
        {{ userDetails.fullName }}
      </h3>
      <b-row class="justify-content-between">
        <b-col cols="12" class="col-sm-6">
          <b-nav role="navigation">
            <b-nav-item href="#" active>Details</b-nav-item>
          </b-nav>
        </b-col>
        <b-col cols="12" class="col-sm-6 text-right mt-n4">
          <b-button
            type="button"
            v-activeBlur
            :disabled="restrictUsersToMultipleCity || validateUserToSelectedTenant"
            @click="activateUserConfirmModal = true"
            class="transparent mr-3"
            >{{ userDetails.isActive ? `Deactivate` : `Activate` }}</b-button
          >
          <!-- <b-button
            type="button"
            v-activeBlur
            :disabled="restrictUsersToMultipleCity || validateUserToSelectedTenant"
            class="transparent mr-3"
            @click="resetPasswordConfirmModal = true"
            >Reset password</b-button
          > -->
        </b-col>
      </b-row>
    </section>
    <LoaderIcon v-if="getUserDetailsLoading" />
    <section class="px-5 mt-4" v-else-if="restrictUsersToMultipleCity">
      <b-form-row>
        <b-col>
          <b-alert show variant="danger">{{ DISPLAY_MESSAGES.MULTIPLE_CITIES_USER_DETAILS_ERROR }}</b-alert>
        </b-col>
      </b-form-row>
    </section>
    <section class="px-5 mt-4" v-else-if="validateUserToSelectedTenant">
      <b-form-row>
        <b-col>
          <b-alert show variant="danger">{{ DISPLAY_MESSAGES.USER_NOT_BELONG_TO_TENANT }}</b-alert>
        </b-col>
      </b-form-row>
    </section>
    <div class="p-5" v-else-if="!userDetails">
      <h5>User details not available.</h5>
    </div>
    <div class="px-5" v-else>
      <section class="pt-5 pb-4 border-bottom-1">
        <h3 class="page-header page-header-highlight">Personal information</h3>
        <b-row class="mt-3 break-all">
          <b-col cols="12" sm="6" md="4" id="user-authenticated-by">
            Authenticated by:
            {{ userDetails.cityName ? userDetails.cityName : 'NA' }}
          </b-col>
          <b-col cols="12" sm="6" md="4">
            Email:
            <a :href="`mailto:${userDetails.userEmail}`">{{ userDetails.userEmail }}</a>
          </b-col>
          <b-col cols="12" sm="6" md="4" id="user-telephone">
            Tel:
            {{ userDetails.phoneNumber ? userDetails.phoneNumber : 'NA' }}
          </b-col>
        </b-row>
      </section>
      <section class="pt-4 pb-4 border-bottom-1">
        <h3 class="page-header page-header-highlight">Properties</h3>
        <h5 class="py-4 text-center" v-if="userDetails.addresses && !userDetails.addresses.length">No properties available.</h5>
        <b-row class="mt-3" v-else>
          <b-col cols="12" sm="6" md="3" class="pb-3 property-lists" v-for="(address, index) in userDetails.addresses.slice(0, propertyLimit)" :key="index">
            <h6 class="theme-font-medium" :class="{ 'pb-3': !address.isSelected && !address.addressLabel }">
              {{ getPrimaryPropertyLabel(address) }}
            </h6>
            <p class="mb-1">
              {{ address.streetNumber }} {{ address.streetName }}
              {{ address.unitNumber ? `, ${address.unitNumber}` : '' }}
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3" v-if="userDetails.addresses && userDetails.addresses.length > 4">
          <b-col class="text-center">
            <a href="javascript:void(0)" role="button" @click="propertyLimit = propertyLimit > 4 ? 4 : userDetails.addresses.length" class="theme-font-bold">{{
              propertyLimit > 4 ? 'View less' : 'View more'
            }}</a>
          </b-col>
        </b-row>
      </section>
    </div>
    <ConfirmationModal
      :showModal="activateUserConfirmModal"
      :title="`CONFIRM`"
      :message="userDetails && userDetails.isActive ? `DEACTIVATE_USER` : `ACTIVATE_USER`"
      @onConfirm="activateOrDeactivateUser"
      @closeConfirmModal="activateUserConfirmModal = false"
    />
    <ConfirmationModal
      :showModal="resetPasswordConfirmModal"
      :title="`CONFIRM`"
      :message="`RESET_PASSWORD_USER`"
      @onConfirm="resetPassword"
      @closeConfirmModal="resetPasswordConfirmModal = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import LoaderIcon from '../../assets/svg/loader.svg'
import { BIconArrowLeftShort } from 'bootstrap-vue'
import { DISPLAY_MESSAGES } from '../../utilities/constants'
import { useAxios } from '@/composables/useAxios'
import { ServiceUrls } from '@/utilities/serviceUrls'

export default {
  name: 'UserDetails',
  setup() {
    const { isLoading: getUserDetailsLoading, response: userDetails, error: getUserDetailsError, callApi: getUserDetailsApi } = useAxios()
    const {
      isLoading: activateDeactivateLoading,
      response: activateDeactivateResponse,
      error: activateDeactivateError,
      callApi: activateDeactivateApi,
    } = useAxios()
    const { isLoading: resetPasswordLoading, response: resetPasswordResponse, error: resetPasswordError, callApi: resetPasswordApi } = useAxios()
    return {
      getUserDetailsLoading,
      userDetails,
      getUserDetailsError,
      getUserDetailsApi,
      activateDeactivateLoading,
      activateDeactivateResponse,
      activateDeactivateError,
      activateDeactivateApi,
      resetPasswordLoading,
      resetPasswordResponse,
      resetPasswordError,
      resetPasswordApi,
    }
  },
  data() {
    return {
      propertyLimit: 4,
      activateUserConfirmModal: false,
      resetPasswordConfirmModal: false,
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
    }
  },
  components: {
    LoaderIcon,
    BIconArrowLeftShort,
    ConfirmationModal: () => import('../common/ConfirmationModal.vue'),
  },
  computed: {
    restrictUsersToMultipleCity() {
      return this.selectedTenant.length > 1 || this.selectedTenant.some((tenant) => tenant.tenantID === 0)
    },
    validateUserToSelectedTenant() {
      return this.selectedTenant.length === 1 && this.userDetails && this.selectedTenant[0].tenantID !== this.userDetails.tenantId
    },
    getPrimaryPropertyLabel() {
      return (address) => {
        return address.isSelected ? 'Primary property' : address.addressLabel ? address.addressLabel : ``
      }
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
    }),
  },
  created() {
    this.getUserDetails()
  },
  methods: {
    async getUserDetails() {
      await this.getUserDetailsApi({ method: 'get', url: `${ServiceUrls.getUserDetails}/${this.$route.params.userObjectID}` })
      if (this.getUserDetailsError) {
        this.$store.commit('common/setCustomToastData', {
          message: false,
          key: 'USER_DETAILS_ERROR',
          type: 'danger',
        })
      }
    },
    async activateOrDeactivateUser() {
      const payload = [
        {
          userObjectID: this.userDetails.userObjectID,
          isActive: !this.userDetails.isActive,
        },
      ]
      await this.activateDeactivateApi({ method: 'post', url: ServiceUrls.activateOrDeactivateUser, data: payload })
      this.activateUserConfirmModal = false
      if (this.activateDeactivateResponse) {
        this.$store.commit('common/setCustomToastData', {
          message: false,
          key: !this.userDetails.isActive ? 'ACTIVATE_USER' : 'DEACTIVATE_USER',
          type: 'success',
        })
        this.getUserDetails()
      }
      if (this.activateDeactivateError) {
        if (this.activateDeactivateError?.errorMessage) {
          this.$store.commit('common/setCustomToastData', {
            message: this.activateDeactivateError?.errorMessage,
            key: false,
            type: 'danger',
          })
        } else {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger',
          })
        }
      }
    },
    async resetPassword() {
      const payload = [this.userDetails.userObjectID]
      await this.resetPasswordApi({ method: 'post', url: ServiceUrls.resetPassword, data: payload })
      this.resetPasswordConfirmModal = false
      if (this.resetPasswordResponse) {
        this.$store.commit('common/setCustomToastData', {
          message: false,
          key: 'RESET_PASSWORD_SUCCESS',
          type: 'success',
        })
      }
      if (this.resetPasswordError) {
        if (this.resetPasswordError?.errorMessage) {
          this.$store.commit('common/setCustomToastData', {
            message: this.resetPasswordError?.errorMessage,
            key: false,
            type: 'danger',
          })
        } else {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'RESET_PASSWORD_ERROR',
            type: 'danger',
          })
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
